import { Portfolio1, Portfolio2, Portfolio3, Portfolio4, Portfolio5 } from '../../assets';
import './Portfolio.scss';
const portfolioList = [
    {
        image: Portfolio1,
        label: 'Webverse',
        link : 'https://twitter.com/WebverseNFT'
    },
    {
        image: Portfolio2,
        label: 'eternalAI',
        link : 'https://twitter.com/CryptoEternalAI'
    },
    {
        image: Portfolio3,
        label: 'Saku Monsters',
        link : 'https://twitter.com/sakumonsters'
    },
    {
        image: Portfolio4,
        label: 'Redacted Coin',
        link : 'https://twitter.com/redactedcoin'
    },
    {
        image: Portfolio5,
        label: 'BVMnetwork',
        link : 'https://twitter.com/BVMnetwork'
    }
]

const Portfolio = () => {

    return (
        <div className="portfolio-container">
            <span className='label'>
                <p>Portfolio</p>
                <span className='underline'></span>
            </span>
            <div className="portfolio-wrapper">
                {portfolioList.map(portfolioItem => {
                    return (
                        <div className='portfolio-item'>
                            <span className='portfolio-item-label'>{portfolioItem.label}</span>
                            <a href={portfolioItem.link} target='_blank'>
                                <img src={portfolioItem.image} alt="" />
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Portfolio;