import { MemberAvatar1, MemberAvatar2, MemberAvatar3, MemberAvatar4, MemberAvatar5} from "../../assets";
import MemberCard from "../../components/member_card/MemberCard";
import './Team.scss';

const teamList = [
    {
        name: 'giamdoxlong',
        avatar: MemberAvatar1,
        twitter: 'https://twitter.com/giamdoxlong',
        title: 'Council'
    },
    {
        name: 'jira',
        avatar: MemberAvatar2,
        twitter: 'https://twitter.com/jira_oasis',
        title: 'Macro Researcher'
    },
    {
        name: 'Tien Chau Le',
        avatar: MemberAvatar3,
        twitter: 'https://twitter.com/TienChauLe',
        title: 'Business Development'
    },
    {
        name: 'NhimZ',
        avatar: MemberAvatar4,
        twitter: 'https://twitter.com/NhimZ3005',
        title: 'Business Development'
    },
    {
        name: 'Richie',
        avatar: MemberAvatar5,
        twitter: 'https://twitter.com/richieweb3',
        title: 'Onchain Researcher'
    }
]

const Team = () => {

    return (
        <div className="team-container">
            <span className='label'>
                <p>Team</p>
                <span className='underline'></span>
            </span>
            <div className="team-wrapper">
                {teamList.map(member => {
                    return (
                        <div className="team-item">
                            <MemberCard member={member} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Team;
