import { TwitterLogo } from '../../assets';
import './MemberCard.scss';


const MemberCard = ({ member }) => {

    return (
        <div className='member-card-container'>
            <div className='member-card-container-image'>
                <img src={member.avatar} />
            </div>
            <div className='member-card-info'>
                <span className='member-card-info-name'>{member.name}</span>
                <span className='member-card-info-title'>{member.title}</span>
                <span className='member-card-info-twitter'>
                    <a href={member.twitter} target='_blank'>
                        <img src={TwitterLogo} />
                    </a>
                </span>
            </div>
        </div>
    )
}
export default MemberCard;