import './Writing.scss';

const Writing = () => {

    return (
        <div className="writing-container">
            <span className='label'>
                <p>Writing</p>
                <span className='underline'></span>
            </span>
            <div className='writing-content'>
                <p>comming soon</p>
            </div>
        </div>
    )
}
export default Writing;
