import './Home.scss';

const Home = () => {

    return (
        <div className="home-container">
            <span className='label'>
                <p>Elevating Web3</p>
                <p>with AB Ventures</p>
                <span className='underline'></span>
            </span>
            <p className='home-content'>At AB Ventures, we're reshaping Web3 venture capital by championing undiscovered innovators in blockchain and crypto. Our mantra, "Elevating Web3 with AB Ventures" reflects our commitment to breaking the traditional funding mold, offering resources, knowledge, and support to promising projects often overlooked by conventional VC models.</p>
            <p className='home-content'>We aim to foster a diverse and inclusive Web3 ecosystem, ensuring every groundbreaking idea gets the spotlight it deserves, irrespective of its origins. AB Ventures is more than a fund; it's a catalyst for change, driving innovation and inclusivity in the digital frontier. Join us in building a vibrant future for Web3, where every visionary has a voice.</p>
        </div>
    )
}

export default Home;