import { useLocation } from "react-router-dom";
import './App.scss';
import { Logo } from "./assets";
import Home from "./page/home/Home";
import Team from "./page/team/Team";
import Portfolio from "./page/portfolio/Portfolio";
import Writing from "./page/writing/Writing";
import Contact from "./page/contact/Contact";

const pageList = [
  {
    handle: 'home',
    title: 'Home',
    content: <Home />
  },
  {
    handle: 'team',
    title: 'Team',
    content: <Team />
  },
  {
    handle: 'portfolio',
    title: 'Portfolio',
    content: <Portfolio />
  },
  {
    handle: 'writing',
    title: 'Writing',
    content: <Writing />
  },
  {
    handle: 'contact',
    title: 'Contact',
    content: <Contact />
  },
]

const App = () => {

  const location = useLocation();
  const hash = location.hash;

  return (
    <div className="container">
      <div className="main">
        <div className="menu-container">
          <div className="menu-logo">
            <div className="menu-logo-image">
              <img src={Logo} />
            </div>
            <div className="menu-logo-text">
              <p className="menu-logo-text-large">AB Ventures</p>
              <p className="menu-logo-text-small">ELEVATING WEB3</p>
            </div>
          </div>
          <div className="menu-list">
            {pageList.map(page => {
              return (
                <li className={hash.includes(page.handle) ? 'menu-item active' : 'menu-item'}><a href={`#${page.handle}`}>{page.title}</a></li>
              )
            })}
          </div>
        </div>
        <div className="page-container">
          <div className="mobile menu-logo">
            <div className="menu-logo-image">
              <img src={Logo} />
            </div>
            <div className="menu-logo-text">
              <p className="menu-logo-text-large">AB Ventures</p>
              <p className="menu-logo-text-small">ELEVATING WEB3</p>
            </div>
          </div>
          <div className="page-wrapper">
            {pageList.map(page => {
              return (
                <div className="page-item" id={page.handle}>
                  <div className="page-item-content">
                    {page.content}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
