import './Contact.scss';

const Contact = () => {

    return (
        <div className="contact-container">
            <span className='label'>
                <p>Contact</p>
                <span className='underline'></span>
            </span>
            <div className='contact-content'>
                <p>Email: pitch@ab.ventures</p>
                <p>Twitter: @_abventures</p>
            </div>
        </div>
    )
}
export default Contact;
